import React from "react";
import router from "next/router";
// import { useRouter } from "next/router";
import { openInNewTab } from "../../helpers/openInNewTab";
function PrimaryButton({
  buttonText,
  version,
  linkTo,
  clickHandle,
  shine,
  buttonState,
  linkOnBlank,
  noShadow,
  customWidth,
  customWidthValue,
}) {
  const clickHandler = () => {
    if (clickHandle) {
      clickHandle();
    }
    if (linkTo) {
      if (linkOnBlank) return openInNewTab(linkTo);
      router.push(linkTo);
    }
  };

  return (
    <div
      className={`primary-button ${version} ${shine ? "shine" : ""} ${
        buttonState ? buttonState : ""
      } ${noShadow ? "no-shadow" : ""}`}
      onClick={clickHandler}
      style={customWidth && { width: customWidthValue }}
    >
      {version == "version-6" ? <p>{buttonText}</p> : buttonText}
      {version === "version-2" ? (
        <img
          src={
            buttonState === "disabled"
              ? `${process.env.NEXT_PUBLIC_ALL_ASSETS_API}buttonArrowDisabled.svg`
              : `${process.env.NEXT_PUBLIC_ALL_ASSETS_API}buttonArrow.svg`
          }
          className="primary-button-arrow"
          alt="arrow"
        />
      ) : null}
      {version === "version-4" ? (
        <img
          src={
            buttonState === "disabled"
              ? `${process.env.NEXT_PUBLIC_ALL_ASSETS_API}buttonArrowDisabled.svg`
              : `${process.env.NEXT_PUBLIC_ALL_ASSETS_API}buttonArrow.svg`
          }
          className="primary-button-arrow"
          alt="arrow"
        />
      ) : null}
    </div>
  );
}

export default PrimaryButton;
