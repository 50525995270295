import React from "react";

function Coin({ className, css }) {
  return (
    <img
      style={{ objectFit: "contain", height: "9rem", ...css }}
      className="results-report-card__left__reportCard__right--image"
      src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}new-results-coin.png`}
      alt="trophey"
    />
  );
}

export default Coin;
