import router from "next/router";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import {
  openSignup,
  openLogin,
  logoutUser,
} from "../store/actions/authActions";
import { useAlert, positions } from "react-alert";
import { setAuthDestinationPage } from "../store/actions/authActions";

// ! custom hook to handle errors, mainly in catch blocks
export const AuthHook = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { pathname } = useRouter();
  const [alertPosition, setAlertPosition] = useState(positions.TOP_CENTER);

  // ! alert info helper
  const alertInfo = (alertText) => {
    return alert.show(alertText, {
      type: "info",
      position: alertPosition,
    });
  };

  const errorHandler = (status) => {
    alert.removeAll();
    //  ! sesstion expiry for affiliates
    if (
      (status?.code === 40101 || status?.code === 401) &&
      window.location.href.includes("/affiliate")
    ) {
      dispatch(setAuthDestinationPage(pathname));
      dispatch(logoutUser());
      router.push("/affiliate-login");
      alertInfo(status?.message);
      return;
    }
    // ! session exipiry for games
    if (
      (status?.code === 40101 || status?.code === 401) &&
      window.location.href.includes("/play")
    ) {
      dispatch(openLogin());
      return;
    }

    // ! session exipry
    if (status?.code === 40101 || status?.code === 401) {
      dispatch(setAuthDestinationPage(pathname));
      dispatch(logoutUser());
      router.push("/login");
      alertInfo(status?.message);
      return;
    }
    // if (status.code === 500) return alertInfo(status.message);
    return alertInfo(status?.message ?? "Something went wrong.");
  };

  const alertHook = (message = "Submitted") => {
    alertInfo(message);
  };

  return [errorHandler, alertInfo, alertHook];
};
