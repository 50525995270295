import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import gsap from "gsap";
import { useLottie } from "lottie-react";
import noInternet from "./noInternet.json";

function InternetDownModal({ closeModalHandler }) {
  const modalRef = useRef(null);
  const modalWrapperRef = useRef(null);
  const tweenRef = useRef(null);
  const opacityRef = useRef(null);

  const options = {
    animationData: noInternet,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  useEffect(() => {
    modalRef.current.style.display = "flex";
    modalWrapperRef.current.style.display = "flex";
    tweenRef.current = gsap.fromTo(
      modalRef.current,
      { y: `-${window.innerHeight}`, autoAlpha: 1 },
      {
        y: `-${window.innerHeight * 0.1}`,
        autoAlpha: 1,
        duration: 0.6,
        ease: "back",
      }
    );
    opacityRef.current = gsap.fromTo(
      modalWrapperRef.current,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.6 }
    );
  }, []);

  return (
    <div
      className="internet-down-modal__wrapper global-modal-wrapper"
      ref={modalWrapperRef}
    >
      <div className="internet-down-modal" ref={modalRef}>
        <div
          className="internet-down-modal__cross-icon"
          onClick={() => {
            setTimeout(() => {
              closeModalHandler();
            }, 600);
            opacityRef.current.reverse();
            tweenRef.current.reverse();
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}gamesmodal_cross-icon.png`}
            alt=""
          />
        </div>
        <div className="internet-down-modal__networkdown-image">
          <div style={{ width: "20rem" }}>{View}</div>
        </div>
        <div className="internet-down-modal__heading">
          No Internet Connection
        </div>
        <div className="internet-down-modal__subheading">
          Make sure Wi-Fi or mobile data is turned on, then try again
        </div>

        <PrimaryButton
          noShadow
          buttonText="Refresh"
          version="version-5"
          clickHandle={() => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
}

export default InternetDownModal;
