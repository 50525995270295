import axios from "axios";
const setUtmObject = (data) => {
  return { type: "SET_UTM_OBJECT", payload: data };
};

const setPromocodeDetails = (data) => {
  return { type: "SET_MY_PROMOCODE_DETAILS", payload: data };
};

const setIsCombo = (data) => {
  return { type: "SET_IS_COMBO", payload: data };
};
const setCurrency = () => {
  return (dispatch) => {
    if (typeof window === "object" && !window?.sessionStorage?.ipapi_response) {
      axios
        .get(process.env.NEXT_PUBLIC_IPAPI_URL)
        .then((res) => res.data)
        .then((result) => {
          console.log("ipapi", result);
          if (
            result.currency &&
            (result.currency == "INR" ||
              result.currency == "USD" ||
              result.currency == "AED")
          )
            dispatch({ type: "SET_CURRENCY", payload: result.currency });
          else dispatch({ type: "SET_CURRENCY", payload: "USD" });
        })
        .catch((e) => {
          console.log("ipapi-err", e);
        });
    }
  };
};
const setReferrerDetails = (data) => {
  return { type: "SET_REFERRER_DETAILS", payload: data };
};
const setReferrerDetailsThunk = (id, token, data = "") => {
  // console.log("reached here", id, token);
  return (dispatch) => {
    axios
      .post(
        process.env.NEXT_PUBLIC_SUBSCRIBE_FOR_REFERRAL_API,
        {
          co_booking: {
            referrer_code: data,
          },
        },
        {
          headers: {
            "X-SSUID": id,
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log("SUBSCRIPTION API ON LIVE", res.data);
        dispatch({
          type: "SET_REFERRER_DETAILS",
          payload: {
            personal_code: res.data.referral_code,
          },
        });
      })
      .catch(function (error) {
        console.log("subscription error", error);
      });
  };
};
const setPreferredCalendar = (calendar) => {
  return { type: "SET_PREFERRED_CALENDAR", payload: calendar };
};
const setPreferredChildAge = (age) => {
  return { type: "SET_PREFERRED_CHILD_AGE", payload: age };
};
const setAdName = (ad_name) => {
  return { type: "SET_AD_NAME", payload: ad_name };
};
const setBannerActive = (bannerActive) => {
  return { type: "SET_BANNER_ACTIVE", payload: bannerActive };
};
const setBannerUpdateDate = (bannerUpdateDate) => {
  return { type: "SET_BANNER_UPDATE_DATE", payload: bannerUpdateDate };
};

const setReferrelCode = (referralCode) => {
  return { type: "SET_REFERREL_CODE", payload: referralCode };
};

export {
  setUtmObject,
  setCurrency,
  setReferrerDetails,
  setIsCombo,
  setReferrerDetailsThunk,
  setPreferredCalendar,
  setPreferredChildAge,
  setAdName,
  setBannerActive,
  setBannerUpdateDate,
  setReferrelCode,
  setPromocodeDetails,
};
